import SideBar from "./SideBar";
import "./Main.css";
import Head from "./Head";
import { useState } from "react";
import Content from "../login/Content";

const Main = ({user}) => {
    const [isActive,setActive] = useState(false);
    
    return (
        <section className="row">
            <div className="container-xxl position-relative bg-white d-flex p-0">


                {/* <Head /> */}

                <SideBar loggedInUser={user} isActive={isActive} setActive={setActive} />

                <Content user={user} isActive={isActive} setActive={setActive}/>


            </div>
        </section >
    )
}

export default Main;