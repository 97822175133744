import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ToastContainer, toast } from "react-toastify"
import { useFetchCategory } from "../../services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bootstrap } from "/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";


const MAX_FILE_SIZE = 10000000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/pdf"];
const schema = z.object({
    id: z.number().optional(),
    categoryCode: z.string().min(1, "Select a category"),
    name: z.string().min(3, "Minimum of 3 characters required"),
    price: z.number().min(1, "Minimum value of ₦1"),
    discount: z.number().min(0, "Minimum value of ₦0"),
    description: z.string(),
    // fileName: z.string().optional(),
    removeBackground: z.boolean(),
    specifications: z.object({
        property: z.string().optional(),
        value: z.string().optional(),
    }).array(),
    photos: z.object({
        image: z.any()
            .refine((files) => typeof files !== 'string' && files?.[0]?.size <= MAX_FILE_SIZE, `Max image size is 10MB.`)
            .refine(
                (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
                "Only .jpg, .jpeg, .pdf, .png and .webp formats are supported."
            ).optional()

    }).array()
    // image:
    //     z.any()
    //         .refine((files) => typeof files !== 'string' && files?.[0]?.size <= MAX_FILE_SIZE, `Max image size is 10MB.`)
    //         .refine(
    //             (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
    //             "Only .jpg, .jpeg, .pdf, .png and .webp formats are supported."
    //         ).optional()
})

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})
const ProductForm = ({ title, product, action }) => {
    const { data: categories } = useFetchCategory();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    let [isRemoveBackground, setRemoveBackground] = useState(false);

    let [photoArray, setPhotoArray] = useState([]);
    let [deletedPhotoArray, setDeletedPhotoArray] = useState([]);


    const { handleSubmit, register, formState: { errors }, reset, control } = useForm({
        resolver: zodResolver(schema),
        defaultValues: product === null ? "" : product,
        // defaultValues :{
        //    photos: [{image:"man"}],

        // }


    });
    const { fields: fields1, append: append1, remove: remove1 } = useFieldArray({
        control,
        name: 'specifications'

    });
    const { fields: fields2, append: append2, remove: remove2 } = useFieldArray({
        control,
        name: 'photos'

    });

    const findCategory = categories?.content?.find(category => product?.categoryCode === category.code);

    useEffect(() => {
        if (product) {
            let arr = [];
            product.photos.map((photo, index) => arr[index] = photo);
            setPhotoArray(arr);
            remove2();
        }
    }, [remove2,product])

    const handleReset = () => {
        reset();
        while (fields1.length > 0) {
            remove1(fields1.length - 1); // Remove the first field (index 0) until there are no more fields
            fields1.length--;
        }
        remove1(0);
        while (fields2.length > 0) {
            remove2(fields2.length - 1); // Remove the first field (index 0) until there are no more fields
            fields2.length--;
        }
        remove2(0);
    };
    const handleAppendPhoto = () => {
        append2({ 'image': '' });
    }

    const onSubmit = (params) => {
        console.log("" + params)
        const formObjectBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });

        const formData = new FormData();
        formData.append("productDto", formObjectBlob);
        if (product) {
            const deletedPhotosBlob = new Blob([JSON.stringify(deletedPhotoArray)], { type: 'application/json' });
            formData.append('deletedPhotosDto', deletedPhotosBlob);
        }
        for (let i = 0; i < params?.photos?.length; i++) {
            formData.append("files", params?.photos?.[i].image[0]);
        }
        // formData.append("files", params?.image?.[0]); for single image
        console.log("sending")
        action.mutate(formData,
            {
                onSuccess: (res) => {
                    // if (res?.code === "00") {
                        queryClient.invalidateQueries("products");

                        if (product) {
                            toast.success("Product updated successfully");
                        }
                        else {
                            toast.success("Product added successfully");
                            handleReset();
                            // reset({
                            //     price: '',
                            //     name: '',
                            //     categoryCode: '',
                            //     discount: '',
                            //     description: '',
                            //     image: '',
                            //     specifications:[]
                            // }, {
                            //     keepErrors: true,
                            //     keepDirty: true,
                            // });

                        }
                    // }
                },
                onError: () => {
                    toast.error("Failed", { autoClose: false, closeButton: true })
                }
            })
    }
    const backBtn = () => {
        navigate('/view-products');
    }
    //remove file input field
    const handleRemovePhoto = (index) => {
        remove2(index);
    }
    //delete persisted photo
    const deletePhoto = (index) => {
        let arr = [...photoArray];
        let deleted = [...deletedPhotoArray];
        deleted.push(arr.splice(index, 1)[0]);//add the only one item to prevent nested array
        setDeletedPhotoArray(deleted);
        setPhotoArray(arr);
    }

    return (
        <div>
            <ToastContainer position="top-right" />
            <div className="bg-light rounded m-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className=" col-sm-12 col-xl-5">
                            <div className=" h-100 p-4">
                                <h6 className="mb-4">{title}</h6>

                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect"
                                        {...register("categoryCode")}
                                        aria-label="Floating label select example" >
                                        <option value={product?.categoryCode === null ? "" : product?.categoryCode}>
                                            {product?.categoryCode === null ? "..." : findCategory?.name}</option>
                                        {
                                            categories?.content?.map(category => {
                                                return <option key={category.code} value={category.code}>{category.name}</option>
                                            })
                                        }
                                    </select>
                                    <label htmlFor="floatingSelect">Select Category</label>
                                    {errors.categoryCode && <em>{errors.categoryCode.message}</em>}
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="floatingInput"
                                        placeholder="Product name"  {...register("name")} />
                                    <label htmlFor="floatingInput">Product name/Model</label>
                                    {errors.name && <em>{errors.name.message}</em>}
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="price" placeholder="Price"
                                        {...register("price", { setValueAs: (value) => Number(value) })}/>
                                    <label htmlFor="price">Price</label>
                                    {errors.price && <em>{errors.price.message}</em>}
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="discount"
                                        placeholder="Discount" {...register("discount", { setValueAs: (value) => Number(value) })} />
                                    <label htmlFor="Discount">Discount(%)</label>
                                    {errors.discount && <em>{errors.discount.message}</em>}
                                </div>

                                {
                                    fields1.map((field, index) => (
                                        <div key={field.id}>
                                            <input className="form-control" placeholder="Property" {...register(`specifications.${index}.property`)} />
                                            <input className="form-control" placeholder="Value"{...register(`specifications.${index}.value`)} />
                                            <button className="btn btn-secondary mb-2" type="button" onClick={() => { remove1(index) }}>Remove</button>

                                        </div>
                                    ))
                                }
                                <div className="form-floating mt-3">
                                    <button className="btn btn-secondary" type="button" onClick={() => append1({ property: '', value: '' })}>Add specifications(Optional)</button>
                                </div>


                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-5">
                            <div className=" h-100 p-4">
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" placeholder="Leave a comment here"
                                        id="floatingTextarea" style={{ height: '200px' }}
                                        {...register("description")}></textarea>
                                    <label htmlFor="floatingTextarea">Description</label>
                                    {errors.description && <em>{errors.description.message}</em>}
                                </div>

                                {product &&
                                    photoArray.map((photo, index) => (
                                        <tr key={index}>
                                            <td style={{ maxWidth: '150px', wordWrap: 'break-word' }}> {photo.fileName}</td>
                                            <td className=""><a className="nav-link" href={photo.imageUrl}>download</a></td>
                                            <td><button className="btn btn-secondary" type="button" onClick={() => deletePhoto(index)}>Delete</button></td>
                                        </tr>
                                    ))
                                }
                                {
                                    fields2.map((field, index) => (
                                        <div key={field.id} className="mb-2">
                                            <input className="form-control form-control-md" type="file"
                                                {...register(`photos[${index}].image`)} />
                                            <button className="btn btn-secondary mb-2" type='button' onClick={() => handleRemovePhoto(index)}>Remove</button>
                                            {errors.photos && <em>{errors.photos.message}</em>}
                                            {errors.photos?.image && <em>{errors.photos.image.message}</em>}
                                        </div>
                                    ))

                                }
                                <div className="form-floating mt-3">
                                    <button className="btn btn-secondary" type="button" onClick={() => handleAppendPhoto()}
                                        data-bs-toggle="tooltip" data-bs-placement="top-right" title="Minimum of 1 photo and Maximum of 3 photos">Add photo</button>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="removeBackground"
                                        {...register('removeBackground')} onChange={() => setRemoveBackground(!isRemoveBackground)} />
                                    <label className="form-check-label" htmlFor="removeBackground">
                                        Remove image background(incurs extra charges)
                                    </label>
                                </div>
                                <section className="d-flex justify-content-between">
                                    {product && <button type="button" className="btn btn-secondary" style={{ marginRight: '55%' }} onClick={backBtn}>Back</button>}
                                    <button type="submit" className="btn btn-primary me-1" >Save</button>
                                </section>

                            </div>
                        </div>
                    </div>

                </form>
            </div >
        </div >
    )
}
export default ProductForm;