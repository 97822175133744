import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios"

// export const baseURL = "http://localhost:8006";
export const baseURL = "https://dzz2gwfjm4fx2.cloudfront.net";

// export default axios.create({
//     baseURL: baseURL,
// })

axios.interceptors.request.use(function (config) {
    if (localStorage.getItem("token")) {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }

    return config;
});
export const useFetchCategory = (page, size) => {

    let params = {//params is a key word for defining query params
        page: page,
        size: size
    }
    const fetchCategories = () => axios.get(`${baseURL}/category`, params)
        .then((res) => res.data.body).catch(err => console.log(err));


    return useQuery({
        queryFn: () => fetchCategories(),
        queryKey: ["categories", params?.page],
        placeholderData: keepPreviousData,

        // keepPreviousData:true
    })
}
export const useFetchAllProducts = (categoryCode, page, size) => {
    let params = {
        categoryCode: categoryCode,
        page: page,
        size: size
    }
    const fetchProducts = () => axios.get(`${baseURL}/product`,{params} )
        .then(res => res.data.body)
        .catch(err => console.log(err))

    return useQuery({
        queryKey: ["products", params?.page, params?.categoryCode],
        queryFn: fetchProducts,
        placeholderData: keepPreviousData,
    })

}

export const useDropProduct = async (product) => {
    return await axios.put(`${baseURL}/product/admin/drop?id=${product.id}&fileName=${product.fileName}`)
        .then(res => res.data)
        .catch(err => console.log(err))
}
export const deleteCategory = async (code) => await axios.delete(`${baseURL}/category/admin?code=${code}`);

export const useDeleteCategory = () => {
    return useMutation({
        mutationFn: deleteCategory,
    })
}



