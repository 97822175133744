import 'react-toastify/dist/ReactToastify.css';
import { useMutation} from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../../services/ApiClient";
import CategoryForm from "./CategoryForm";


const CreateCategory = ({ username }) => {
    const createCategory = (payload) => axios.post(`${baseURL}/category/admin`, payload).then(res => console.log(res));

    const addCategoryFn = useMutation({
        mutationFn: createCategory,
       
    })
    return (
        <CategoryForm title='Create Category'  action={addCategoryFn} />
    )
}
export default CreateCategory;