import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import Main from "../menu/Main";
import { authenticateUser } from "../../services/userAuthService";
import { jwtDecode } from "jwt-decode";

const schema = z.object({
    username: z.string().min(11, { message: 'Username must not be less than 11 characters' }),
    password: z.string().min(1)
})
const user = localStorage.getItem("user");

const Signin = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
    const [eye, setEye] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(user);
    const [authError,setAuthError] = useState("");

    useEffect(() => {
        if( localStorage.getItem("token")){
            // const user = jwtDecode(localStorage.getItem("token"));
            // setLoggedInUser(user.sub);
            //check token expiration
        }
    },[])

    const onSubmit = async(formData) => {
        try {
           const authentication = await authenticateUser(formData.username, formData.password);
            if (authentication?.status === 200) {
                const token = authentication?.data.token;
                // axios.defaults.headers.common['Authorization'] =`Bearer ${token}`;
                const user = jwtDecode(token);
                // set loggedInUser to firstname and lastname from backend
                localStorage.setItem("user", user.sub);
                localStorage.setItem("token",authentication.data.token)
                setLoggedInUser(user.sub);
            }

        } catch (err) {
            if(err.response && err.response.status ===401){
                setAuthError(err.response.data?.message);
            }else{
                setAuthError("Service unavailable,please try again later");
            }
            console.log(err);
        }

    }
    return (
        <div className="container-fluid">
            {!loggedInUser &&

                <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">

                                <h3 className="text-primary">Sign In</h3>
                            </div>
                            <form action="" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="tel"
                                        {...register("username")} />
                                    <label htmlFor="tel">Phone number</label>
                                    {errors.username && <em>{errors.username.message}</em>}
                                </div>
                                <div className="form-floating mb-4">
                                    <input type="password" className="form-control" id="password" placeholder="Password"
                                        {...register("password")} />
                                    <label htmlFor="password">Password</label>
                                    {errors.password && <em>{errors.password.message}</em>}
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                    </div>
                                    <a className="nav-link text-primary" href="/forgot">Forgot Password</a>
                                </div>
                                {authError && <em style={{color:'red'}}>{authError}</em>}
                                <button type="submit" className="btn btn-primary py-3 w-100 mb-4">Sign In</button>
                                {/* <p className="text-center mb-0">Don't have an Account? <a href="">Sign Up</a></p> */}
                            </form>
                        </div>
                    </div>
                </div>
            }

            {
                loggedInUser && <Main user={loggedInUser} />

            }
        </div>
    )
}

export default Signin;