import MenuTab from "./MenuTab";

const SideBar = ({ loggedInUser, isActive, setActive }) => {
    return (
        <div className={isActive ? "sidebar open pe-4 pb-3 " : "sidebar pe-4 pb-3"}>
            <nav className="navbar bg-light navbar-light">
                <a href="/" className="navbar-brand mx-4 mb-3">
                    <h3 className="text-primary"><i className="fa fa-hashtag me-2"></i>ORANTOOR</h3>
                </a>
                <div className="d-flex align-items-center ms-4 mb-4">
                    <div className="position-relative">
                        {/* <img className="rounded-circle" src={username} alt="Pix" style={{ width: "40px", height: "40px" }} /> */}
                        {/* <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div> */}
                    </div>
                    <div className="ms-3">
                        {/* <h6 className="mb-0">{loggedInUser}</h6> */}
                        {/* <span>Admin</span> */}
                    </div>
                </div>
                <div className="navbar-nav w-100">
                    <MenuTab path="/dashboard" icon="fs-4 bi-speedometer2 me-2" title="Dashboard" />
                    <div className="nav-item dropdown">
                        <a href="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="fa fa-laptop me-2"></i>Category</a>
                        <div className="dropdown-menu bg-transparent border-0">
                            <MenuTab path="view-categories" icon="fa fa-th ms-4" title="View Categories" />
                            <MenuTab path="create-category" icon="fa fa-th ms-4" title="Create Category" />
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i className="bi bi-stack me-2"></i>Product</a>
                        <div className="dropdown-menu bg-transparent border-0">
                            <MenuTab path="view-products" icon="fa fa-th ms-4" title="View Products" />
                            <MenuTab path="add-product" icon="fa fa-th ms-4" title="Add Product" />
                        </div>
                    </div>
                   
                </div>
            </nav>
        </div>
    )
}

export default SideBar;