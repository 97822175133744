import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const CategoryForm = ({ title, category, action }) => {
    const [categoryName, setCategoryName] = useState(()=>category?.name?category.name:'');
    const [description, setDescription] = useState(()=>category?.description ? category.description : '');
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            name: categoryName,
            description: description,
            code: category?.code//applicable for editCategory
        }
        let actionType = "created";
        action.mutate(payload, {
            onSuccess: () => {
                if (null != category?.code) {
                    actionType = "updated";
                }
                toast.success(`${categoryName} category ${actionType} successfully`);
                setCategoryName("");
                setDescription("");
                queryClient.invalidateQueries("categories");

            },
            onError: () => { toast.error(`Error creating ${categoryName} category`, { autoClose: false, closeButton: true }); }
        });
    }
    const backBtn = () => {
        navigate('/view-categories');
    }
    return (
        <div>
            <ToastContainer position="top-right" />
            <div className="col-sm-12 col-xl-12 d-flex justify-content-center">
                <div className="bg-light rounded h-100 p-4 col-xl-8">
                    <h6 className="mb-4">{title}</h6>
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <label htmlFor="categoryName" className="col-sm-2 ">Name</label>
                            <div className="col-sm-10">
                                <input type="text" value={categoryName} className="form-control" id="categoryName"
                                    onChange={(e) => setCategoryName(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="description" className="col-sm-2 ">Description</label>
                            <div className="col-sm-10">
                                <textarea value={description} className="form-control" id="description" rows="3"
                                    onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            {category && <button type="button" className="btn btn-secondary" onClick={backBtn}>Back</button>}
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default CategoryForm;