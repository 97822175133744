import { Link, useNavigate } from "react-router-dom";
import Routing from "./Routing";

const Content = ({user,isActive,setActive}) => {

    const toggleClass = () => {
        setActive(!isActive);
    }

    return (
        <div className={isActive ? "content open" : "content"}>
            <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0   justify-content-between mb-4">

                <button type="button"  onClick={toggleClass} className="nav-link sidebar-toggler flex-shrink-0">
                    <i className="fa fa-bars"></i>
                </button>
                <div className="nav-item dropdown ">
                    <a href="profile" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                        <span className="d-none d-lg-inline-flex">{user}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                        <Link to="/profile" className="dropdown-item">My Profile</Link>
                        <Link to="/settings" className="dropdown-item">Settings</Link>
                        <Link to="/logout" className="dropdown-item">Log Out</Link>
                    </div>
                </div>


            </nav>
            <Routing user={user} isActive={isActive} setActive={setActive}/>

        </div>
    )
}

export default Content;