import { useLocation } from "react-router-dom";
import CategoryForm from "./CategoryForm"
import axios from "axios";
import { baseURL } from "../../services/ApiClient";
import { useMutation } from "@tanstack/react-query";

const EditCategory = ()=>{
const {state} = useLocation();

const editCategory = async (formData) =>await axios.put(`${baseURL}/category/admin`,formData)
                    .then(res=>res.data).catch(err=>console.log(err));
            
    const editCategoryFn = useMutation({
        mutationFn: editCategory,
    });

    return(
        <CategoryForm title='Edit Category' category={state} action={editCategoryFn} />
    )
}
export default EditCategory;