import { useMutation} from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../../services/ApiClient";
import ProductForm from "./ProductForm";

const AddProduct = ({ username }) => {

    const addProduct = async (formData) => await axios.post(`${baseURL}/product/admin`, formData)
    .then(res => res.data).then(err => err);

    const submitProduct = useMutation({
        // mutationKey: ['addProduct'],
        mutationFn: addProduct,
    })

    return (
        <ProductForm title="Add Product" action={submitProduct}/>
    )
}

export default AddProduct;