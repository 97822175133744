import { useLocation } from "react-router-dom";
import ProductForm from "./ProductForm";
import axios from "axios";
import { baseURL } from "../../services/ApiClient";
import { useMutation } from "@tanstack/react-query";

const EditProduct = () => {
    const { state } = useLocation();

    const editProduct = async (formData) => await axios.put(`${baseURL}/product/admin/edit`, formData)
        .then(res => res.data).then(err => console.log(err));

    const editProductFn = useMutation({
        mutationFn: editProduct,
    })

    return (
        <ProductForm title="Edit Product" action={editProductFn} product={state} />
    )
}

export default EditProduct;