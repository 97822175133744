import { useState } from "react";
import { useDeleteCategory, useFetchCategory } from "../../services/ApiClient";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const ViewCategory = () => {

    const [page, setPage] = useState(1);
    const { data, isLoading, isError } = useFetchCategory(page, 5);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    let buttons = () => {
        let arr = [];
        const pages = data?.totalPages;
        if (pages < 5) {
            for (let i = 1; i <= pages; i++) {
                arr.push(<button type="button" key={i} className={page === i ? "btn btn-primary" : "btn btn-light"} value={i} onClick={() => setPage(i)}>{i}</button>);
            }
        } else if (page <= pages) {
            arr.push(<button type="button" key={1} className={page === 1 ? "btn btn-primary" : "btn btn-light"} value={1} onClick={() => setPage(1)}>{1}</button>);
            arr.push(<button type="button" key={2} className={page === 2 ? "btn btn-primary" : "btn btn-light"} value={2} onClick={() => setPage(2)}>{2}</button>);

            if ((page - 1) - 2 > 1) {
                arr.push(<button type="button" key={0} className="btn btn-light" value={0} >...</button>);
            }
            if (page > 1 && page < pages) {
                for (let j = page - 1; j <= page + 1; j++) {
                    if (j === 1 || j === 2) continue;
                    if (j === pages || j === pages - 1) continue;
                    arr.push(<button type="button" key={j} className={page === j ? "btn btn-primary" : "btn btn-light"} value={j} onClick={() => setPage(j)}>{j}</button>);
                }
            }
            if ((pages - 1) - (page + 1) > 1) {
                arr.push(<button type="button" key={0} className="btn btn-light" value={0} >...</button>);
            }

            arr.push(<button type="button" key={pages - 1} className={page === pages - 1 ? "btn btn-primary" : "btn btn-light"} value={pages - 1} onClick={() => setPage(pages - 1)}>{pages - 1}</button>);
            arr.push(<button type="button" key={pages} className={page === pages ? "btn btn-primary" : "btn btn-light"} value={pages} onClick={() => setPage(pages)}>{pages}</button>);
        }
        return arr;
    }
    const editCategory = (category) => {
        navigate('/edit-category', { state: category })
    }
    const deleteCategory = useDeleteCategory();

    const handleDeleteCategory = (code) => {
        deleteCategory.mutateAsync(code)
            .then(res=>{
                queryClient.invalidateQueries("categories");
                toast.success("Category deleted successfully");
            })
            .catch(err=>toast.error("Failed to delete Category"))
    }
    return (

        <div>
            {isLoading && <div>Loading...</div>}
            {isError && <div className="text text-danger">Error loading categories</div>}
            <ToastContainer position="top-right" />

            {data?.content &&

                <div className="col-12">
                    <div className="bg-light rounded h-100 p-4">
                        <h6 className="mb-4">Categories</h6>
                        <div className="table-responsive">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S/No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Description</th>
                                        {/* <th scope="col">Code</th> */}
                                        <th scope="col">Number of Products</th>
                                        <th scope="col">Date Created</th>
                                        <th scope="col">Date Updated</th>
                                        <th scope="col">CreatedBy</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.content.map(category => {
                                            return (
                                                <tr key={category.id}>
                                                    <th scope="row">{category.id}</th>
                                                    <td>{category.name}</td>
                                                    <td>{category.description}</td>
                                                    {/* <td>{category.code}</td> */}
                                                    <td>{category.count}</td>
                                                    <td>{category.createdAt}</td>
                                                    <td>{category.updatedAt}</td>
                                                    <td>{category.createdBy}</td>
                                                    <td><button type="button" className="border-0 btn btn-bg-light" onClick={() => editCategory(category)}>
                                                        <i className="bi bi-pencil-square"></i></button></td>
                                                    <td><button type="button" className="border-0 btn btn-bg-light" onClick={() => handleDeleteCategory(category.code)}>
                                                        <i className="bi bi-trash3"></i></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                <div className="btn-toolbar" role="toolbar">
                                    <div className=" me-2" role="group" aria-label="First group">
                                        {buttons()}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default ViewCategory;

