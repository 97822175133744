import { Route, Routes } from "react-router-dom"
import AddProduct from "../product/AddProduct";
import CreateCategory from "../category/CreateCategory";
import ViewCategory from "../category/ViewCategory";
import ViewProducts from "../product/ViewProducts";
import Dashboard from "../menu/Dashboard";
import Logout from "./Logout";
import EditProduct from "../product/EditProduct";
import EditCategory from "../category/EditCategory";

const Routing = ({ user, isActive, setActive }) => {


    return (
        <Routes>
            <Route path="/" element={<Dashboard user={user}/>} />
            <Route path="/dashboard" element={<Dashboard user={user}/>} />
            <Route path="/view-categories" element={<ViewCategory />} />
            <Route path="/create-category" element={<CreateCategory />} />
            <Route path="/edit-category" element={<EditCategory/>} />
            <Route path="/view-products" element={<ViewProducts />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/edit-product" element={<EditProduct/>} />
        </Routes>
    )

}
export default Routing;