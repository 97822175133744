import axios from "axios";
import { baseURL } from "./ApiClient";

export class User {
    constructor(username, password) {
        this.username = username;
        this.password = password;
        this.isAuthenticated = false;
    }
}
const authenticationUrl = `${baseURL}/auth/authenticate`;

export function authenticateUser(username, password) {

    return axios.post(authenticationUrl, { username, password });

}

