import { useState } from "react";
import { useDropProduct, useFetchAllProducts, useFetchCategory } from "../../services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import currencyFormatter from "../../utils/currencyFormatter";

const ViewProducts = ({ username }) => {
    const [page, setPage] = useState(1);
    const [categoryCode,setCategoryCode] = useState("");
    console.log("data.."+categoryCode)

    const { data, isLoading, isError } = useFetchAllProducts(categoryCode);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    let buttons = () => {
        let arr = [];
        const pages = data?.totalPages;
        if(data?.totalElements === 0){//change later to if(pages === 1) if pagination is done
            //do not display button
        }else if (pages < 5) {
            for (let i = 1; i <= pages; i++) {
                arr.push(<button type="button" key={i} className={page === i ? "btn btn-primary" : "btn btn-light"} value={i} onClick={() => setPage(i)}>{i}</button>);
            }
        } else if (pages >= page) {
            arr.push(<button type="button" key={1} className={page === 1 ? "btn btn-primary" : "btn btn-light"} value={1} onClick={() => setPage(1)}>{1}</button>);
            arr.push(<button type="button" key={2} className={page === 2 ? "btn btn-primary" : "btn btn-light"} value={2} onClick={() => setPage(2)}>{2}</button>);

            if ((page - 1) - 2 > 1) {
                arr.push(<button type="button" key={0} className="btn btn-light" value={0} >...</button>);
            }
            if (page > 1 && page < pages) {
                for (let j = page - 1; j <= page + 1; j++) {
                    if (j === 1 || j === 2) continue;
                    if (j === pages || j === pages - 1) continue;
                    arr.push(<button type="button" key={j} className={page === j ? "btn btn-primary" : "btn btn-light"} value={j} onClick={() => setPage(j)}>{j}</button>);
                }
            }
            if ((pages - 1) - (page + 1) > 1) {
                arr.push(<button type="button" key={0} className="btn btn-light" value={0} >...</button>);
            }

            arr.push(<button type="button" key={pages - 1} className={page === pages - 1 ? "btn btn-primary" : "btn btn-light"} value={pages - 1} onClick={() => setPage(pages - 1)}>{pages - 1}</button>);
            arr.push(<button type="button" key={pages} className={page === pages ? "btn btn-primary" : "btn btn-light"} value={pages} onClick={() => setPage(pages)}>{pages}</button>);
        }
        return arr;
    }
    const dropProduct = useMutation({
        mutationFn: useDropProduct,
        onSuccess: () => {
            queryClient.invalidateQueries("products");
            toast.info("Product deleted successfully");
        },
        onError: () => {
            toast.info("Failed to delete product", { autoClose: false, closeButton: true });
        }

    })
    const deleteProduct = (id,fileName) => {
        //Todo:handle failed product deletion
        dropProduct.mutateAsync({id,fileName});//mutation takes only one param which is an object
    }
    const editProduct = (product) => {
        navigate("/edit-product", { state: product })
    }
    const {data:categories} = useFetchCategory();
   

    return (

        <div>
            <ToastContainer position="top-right" />
            {isLoading && <div>Loading...</div>}
            {isError && <div className="text text-danger">Error loading products</div>}
            {data?.content &&

                <div className="col-12">
                    <div className="bg-light rounded h-100 p-4">
                        <section className="d-flex justify-content-between">
                            <h6 className="mb-4">Products</h6>
                            <div className="col-md-4 pb-4">
                                <div className="d-flex">
                                    <select className="form-control" onChange={(e)=>setCategoryCode(e.target.value)}>
                                       <option value="">Filter</option>
                                        {
                                           categories?.content?.map((category)=>{
                                               return <option key={category.id} 
                                               value={category.code}>{category.name}</option>
                                           })
                                        }
                                       
                                    </select>
                                </div>
                            </div>
                        </section>

                        <div className="table-responsive">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S/No</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Price(₦)</th>
                                        <th scope="col">Discount(%)</th>
                                        <th scope="col">Discounted Price(₦)</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.content.map(product => {
                                            return (
                                                <tr key={product.id}>
                                                    <th scope="row">{product.displayId}</th>
                                                    <td>{product.categoryName}</td>
                                                    <td>{product.name}</td>
                                                    <td>{currencyFormatter(product.price)}</td>
                                                    <td className="text-center">{product.discount}</td>
                                                    <td>{currencyFormatter(product.discountedPrice)}</td>
                                                    <td className='w-25'>{product.description}</td>
                                                    <td><a className="nav-link" href={product.imageUrl} rel="noreferrer">View Image</a></td>
                                                    <td><button type="button" className="border-0 btn btn-bg-light" onClick={() => editProduct(product)}>
                                                        <i className="bi bi-pencil-square"></i></button></td>
                                                    <td><button type="button" className="border-0 btn btn-bg-light" onClick={() => deleteProduct(product.id,product.fileName)}>
                                                        <i className="bi bi-trash3"></i></button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                <div className="btn-toolbar" role="toolbar">
                                    <div className=" me-2" role="group" aria-label="First group">
                                        {buttons()}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default ViewProducts;